import { MutableRefObject, useEffect, useRef } from 'react';
import { isDisposable } from '../interfaces/disposable';

import './activity-component.scss';
import { useAssetLocationWatcher } from '../pixi/use-asset-location-watcher';

type Props<T> = {
  constructor: new (container: HTMLDivElement) => T;
  controller?: ActivityController<T> | undefined;
  container?: MutableRefObject<HTMLDivElement | null>;
};

export type ActivityController<T> = {
  bind(activity: T): void;
  unbind(activity: T): void;
};

export const ActivityComponent = function <T>({ constructor, controller, container }: Props<T>) {
  const activityRef = useRef<T | null>(null);
  const defaultContainerRef = useRef<HTMLDivElement>(null);
  const ref = container || defaultContainerRef;

  useAssetLocationWatcher();

  useEffect(() => {
    if (activityRef.current == null) {
      activityRef.current = new constructor(ref.current!);
      controller?.bind(activityRef.current);
    }
    return () => {
      if (activityRef.current) {
        controller?.unbind(activityRef.current);
        if (isDisposable(activityRef.current)) activityRef.current.dispose();
        activityRef.current = null;
      }
    };
  }, [container]);

  return <div ref={ref} className="activity-stage"></div>;
};
